import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { createContact } from "../controllers/main";

import CInput from "../components/input";
import CButton from "../components/button";

import ContactUsWEBP from "../assets/images/contact_us.webp";

function SLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [website, setWebsite] = useState(null);

  const onClick = async () => {
    const res = await dispatch(
      createContact({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        website: website,
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-row items-center justify-center h-[100%] w-[100%] bg-container-background">
        <div className="hidden sm:flex sm:flex-col sm:items-center sm:justify-center h-[100%] sm:w-[50%]">
          <img className="h-[70%] w-[70%] object-contain" src={ContactUsWEBP} />
        </div>
        <div className="flex flex-col h-[100%] w-[100%] sm:w-[50%] p-[2%]">
          <p className="text-[18px] font-[700] self-center">
            {t("contact_us")}
          </p>
          <div className="flex flex-col justify-start h-[100%] w-[100%] overflow-scroll">
            <div className="h-[2%]" />
            <CInput
              label={t("first_name")}
              type="text"
              className="mb-[14px] mt-[5px]"
              placeholder={t("enter_your_first_name")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <div className="h-[2%]" />
            <CInput
              label={t("last_name")}
              type="text"
              className="mb-[14px] mt-[5px]"
              placeholder={t("enter_your_last_name")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <div className="h-[2%]" />
            <CInput
              label={t("email")}
              type="email"
              className="mb-[14px] mt-[5px]"
              placeholder={t("enter_your_email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="h-[2%]" />
            <CInput
              label={t("phone")}
              type="text"
              className="mb-[14px] mt-[5px]"
              placeholder={t("enter_your_phone")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="h-[2%]" />
            <CInput
              label={t("website")}
              type="text"
              className="mb-[14px] mt-[5px]"
              placeholder={t("enter_your_website")}
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
            <div className="flex-grow" />
          </div>
          <CButton
            className="my-[14px] w-[100%]"
            text={t("continue")}
            onClick={onClick}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default SLogin;
