class AppResponse {
  success;
  data;
  error;
  errorMessage;
  action;

  constructor(props) {
    Object.assign(this, props);
  }

  static failure({ errorMessage = "Something went off the rails." } = {}) {
    const obj = {
      success: false,
      errorMessage: errorMessage,
    };

    return new AppResponse(obj);
  }

  static parse(map = {}) {
    const obj = {
      success: map["success"] ?? false,
      data: map["data"],
      error: map["error"],
      errorMessage: map["error_message"],
      action: map["action"],
    };

    return new AppResponse(obj);
  }
}

export { AppResponse };
