import { createBrowserRouter } from "react-router-dom";

import SSplash from "../screens/splash";
import SHome from "../screens/home";

const splashRouter = createBrowserRouter([
  {
    path: "*",
    element: <SSplash />,
  },
]);

const mainRouter = createBrowserRouter([
  {
    path: "*",
    element: <SHome />,
  },
  {
    path: "/home",
    element: <SHome />,
  },
]);

export { splashRouter, mainRouter };
