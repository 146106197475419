function CButton({ text, onClick, isLoading, className, style }) {
  return (
    <div
      className={`flex flex-row items-center justify-center bg-primary text-white rounded-[1rem] px-[0.6rem] py-[0.6rem] cursor-pointer ${
        className ?? ""
      }`}
      onClick={!isLoading ? onClick : null}
      style={style}
    >
      {!isLoading ? (
        <p>{text}</p>
      ) : (
        <div className="loading loading-spinner loading-sm"></div>
      )}
    </div>
  );
}

export default CButton;
