import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import * as AppRoutes from "../utils/routes";

const {
  nullValidator,
  emailValidator,
  validate,
  phoneValidator,
} = require("../utils/validators");

const initialState = {
  router: AppRoutes.splashRouter,
  isLoading: false,
};

const createContact = createAsyncThunk("main/createContact", async (args) => {
  const { first_name, last_name, email, phone, website } = args;

  const valError = validate([
    () => nullValidator("First Name", first_name, { allowNull: true }),
    () => nullValidator("Last Name", last_name, { allowNull: true }),
    () => emailValidator("Email", email),
    () => phoneValidator("Phone", phone, { allowNull: true }),
    () => nullValidator("Website", website, { allowNull: true }),
  ]);
  if (valError) {
    toast.error(valError);
    return;
  }

  const str = await AppNetworkRest.createContact(args);

  return str;
});

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setRouter: (state, action) => {
      state.router = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createContact.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        toast.success(action.payload);
      }
    });
  },
});

export const { setRouter } = mainSlice.actions;

export { createContact };

export default mainSlice.reducer;
