import axios from "axios";
import toast from "react-hot-toast";
import localforage from "localforage";

import { AppResponse } from "./models";

const RequestTypes = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

// const serverURL = "http://localhost:4001";
// const serverURL = "https://ultrasolar-dev-server.umarbinayaz.com";
const serverURL = "https://server.ultrasolar.app";

async function createContact({ first_name, last_name, email, phone, website }) {
  try {
    const url = `${serverURL}/contacts/create_contact_us`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        first_name,
        last_name,
        email,
        phone,
        website,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function _request({ requestType, url, headers, body, orotund = true }) {
  let appResponse;

  try {
    const res = await axios({
      method: requestType,
      url: url,
      data: body,
      headers: headers,
    });

    const data = res.data;

    appResponse = AppResponse.parse(data);
  } catch (e) {
    appResponse = AppResponse.failure();
  }

  if (!appResponse.success && orotund) {
    toast.error(appResponse.errorMessage);
  }

  return appResponse;
}

async function _headers(props = { parseAppUser: true }) {
  const obj = {};

  obj["x-access-token"] = `Basic ${process.env.REACT_APP_X_ACCESS_TOKEN}`;

  if (props.parseAppUser) {
    const appUser = await localforage.getItem("appUser");

    if (appUser != null) {
      obj["authorization"] = `Bearer ${appUser.token}`;
    }
  }

  return obj;
}

export { createContact };
